<template>
  <div class="box has-max-width">
    <Heading size="5" :text="$t('profile.settings.password.title')" />

    <Paragraph :text="$t('profile.settings.password.noAccessToPassword')" />
    <Paragraph :text="$t('profile.settings.password.passwordIsUnique')" />
    <Paragraph :text="$t('profile.settings.password.passwordRequirements')" />

    <Form @submit="submit">
      <div class="columns">
        <div class="column">
          <FormField :label="$t('profile.settings.password.newPassword')">
            <PasswordInputField
              v-model="password1"
              :invalid="passwordInvalid"
              :disabled="isSubmitting">
            </PasswordInputField>
          </FormField>
        </div>

        <div class="column">
          <FormField :label="$t('profile.settings.password.repeatPassword')">
            <PasswordInputField
              v-model="password2"
              :invalid="passwordInvalid"
              :disabled="isSubmitting">
            </PasswordInputField>
          </FormField>
        </div>
      </div>

      <HelpText v-if="passwordInvalid" type="danger" :text="$t('auth.join.passNoMatch')" />

      <Button
        type="primary"
        :loading="isSubmitting"
        :title="$t('profile.settings.password.changePassword')"
        @click="submit">
      </Button>
    </Form>
  </div>
</template>

<script>
const STATUS_DEFAULT = ''
const STATUS_SUBMITTING = 'submitting'
const STATUS_PASSWORD_MISMATCH = 'password-mismatch'
const STATUS_PASSWORD_INVALID = 'password-invalid'

export default {
  data () {
    return {
      status: STATUS_DEFAULT,
      password1: '',
      password2: ''
    }
  },

  computed: {
    isSubmitting () {
      return this.status === STATUS_SUBMITTING
    },

    passwordInvalid () {
      return this.status === STATUS_PASSWORD_INVALID || this.status === STATUS_PASSWORD_MISMATCH
    }
  },

  watch: {
    password1 () {
      this.status = STATUS_DEFAULT
    },

    password2 () {
      this.status = STATUS_DEFAULT
    }
  },

  methods: {
    async submit () {
      if (this.validate()) {
        try {
          this.status = STATUS_SUBMITTING

          const user = this.$store.getters['user/data']

          await this.$store.dispatch('user/changePassword', {
            userId: user.id,
            password: this.password1
          })

          this.$store.dispatch('auth/setPassword', this.password1)
          this.$notification.success(this.$i18n.t('profile.settings.password.passwordChangedMessage'))

          this.password1 = this.password2 = ''
        } catch (error) {
          this.$notification.danger(this.$i18n.t('general.errorOccured'))
          console.error(error)
        } finally {
          this.status = STATUS_DEFAULT
        }
      }
    },

    validate () {
      if (this.password1 !== this.password2) {
        this.status = STATUS_PASSWORD_MISMATCH
        return false
      } else if (this.password1.length < 6) {
        this.status = STATUS_PASSWORD_INVALID
        return false
      }

      return true
    }
  }
}
</script>
